import { Box } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { ExperiencesSearchControl } from "../../../search/components/ExperiencesSearchControl";
import "./styles.scss";
import { AvailabilitySearchControlConnectorProps } from "./container";
import H from "history";

export interface IAvailabilitySearchControlProps
  extends AvailabilitySearchControlConnectorProps {
  history: H.History;
  scrolled?: any;
}

export const AvailabilitySearchControl = (
  props: IAvailabilitySearchControlProps
) => {
  const { scrolled } = props;

  return (
    <Box
      className={clsx("experiences-availability-search-container", {
        scrolled: scrolled,
      })}
    >
      <Box className="experiences-availability-search-control">
        <Box className="search-components-section">
          <ExperiencesSearchControl
            displaySearchOnChangeOnly
            updateParamOnSearch
          />
        </Box>
      </Box>
    </Box>
  );
};
