import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "./styles.scss";
import H from "history";
import {
  ExperienceAvailability,
  GroupedExperienceIds,
  RewardsAccount,
} from "redmond";
import {
  BackButton,
  ExperiencesAvailabilityCard,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { getEarnTagText } from "../../textConstants";
import { PATH_SHOP } from "../../../../utils/paths";
import { transformToStringifiedShopQuery } from "../../../../utils/queryStringHelpers";
import { Skeleton } from "@material-ui/lab";

export interface IExperiencesCategoryRowProps {
  history: H.History;
  experiencesMap: Map<string, ExperienceAvailability>;
  experienceByCategory: GroupedExperienceIds;
  largestValueAccount?: RewardsAccount;
  rewardsKey?: string;
  onBadgeExcellenceTagClick: () => void;
  fromDate: Date | null;
  untilDate: Date | null;
  isSkeleton: boolean;
}

export const ExperiencesCategoryRow = (props: IExperiencesCategoryRowProps) => {
  const {
    experiencesMap,
    experienceByCategory,
    rewardsKey,
    largestValueAccount,
    onBadgeExcellenceTagClick,
    fromDate,
    untilDate,
    isSkeleton,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const currentCategoryName = experienceByCategory.tag.name;
  const currentCategoryId = experienceByCategory.tag.id;

  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const determineOverFlow = () => {
    const overFlowedElement = document.getElementById(
      `experiences-category-${currentCategoryId}`
    );
    if (overFlowedElement) {
      return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
    }
    return false;
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(
      `experiences-category-${currentCategoryId}`
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };
  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(
      `experiences-category-${currentCategoryId}`
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  return (
    <Box className="experiences-category-row-root">
      <Box className={clsx("experiences-category-row-title-container")}>
        {isSkeleton ? (
          <Skeleton
            className={clsx("experiences-category-row-title-skeleton")}
          />
        ) : (
          <Typography
            variant="h3"
            className={clsx("experiences-category-row-title")}
          >
            {`${experienceByCategory.tag.name} (${experienceByCategory.experienceIds.length})`}
          </Typography>
        )}
      </Box>
      <Box className={clsx("experiences-category-row-content")}>
        {!matchesMobile && isOverFlow && (
          <BackButton
            aria-label={`See more experiences in ${currentCategoryName}`}
            onClick={leftScrollButton}
            className="left-scroll-button"
          />
        )}
        <Box
          className="experiences-category-wrapper"
          id={`experiences-category-${currentCategoryId}`}
        >
          {experienceByCategory.experienceIds.map((experienceId) => {
            let experienceAvailabilityInfo = experiencesMap.get(
              experienceId.value
            );

            if (experienceAvailabilityInfo || isSkeleton) {
              // TODO: Remove this it's to deal with mock data
              experienceAvailabilityInfo &&
                (experienceAvailabilityInfo.rating.reviewAverage = Math.floor(
                  Math.random() * (4 - 0 + 1) + 0
                ));
              /////
              return (
                <ExperiencesAvailabilityCard
                  onClick={() => {
                    window.open(
                      `${PATH_SHOP}${transformToStringifiedShopQuery(
                        experienceId,
                        fromDate,
                        untilDate
                      )}`,
                      "_blank"
                    );
                  }}
                  key={experienceAvailabilityInfo?.id.value}
                  experiencesAvailabilityInfo={experienceAvailabilityInfo}
                  variant="card"
                  rewardsKey={rewardsKey}
                  earnTagContent={
                    largestValueAccount && (
                      <>
                        <Icon name={IconName.StarIcon} />
                        <Typography
                          className="earn-tag-text"
                          dangerouslySetInnerHTML={{
                            __html: getEarnTagText(
                              // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE https://hopper-jira.atlassian.net/browse/COTA-1548
                              largestValueAccount.earn.hotelsMultiplier,
                              largestValueAccount.rewardsBalance
                                .currencyDescription ??
                                largestValueAccount.rewardsBalance.currency
                            ),
                          }}
                        />
                      </>
                    )
                  }
                  onBadgeExcellenceTagClick={onBadgeExcellenceTagClick}
                  isSkeleton={isSkeleton}
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
        {!matchesMobile && isOverFlow && (
          <BackButton
            aria-label={`See more experiences in ${currentCategoryName}`}
            onClick={rightScrollButton}
            className="right-scroll-button"
          />
        )}
      </Box>
    </Box>
  );
};
