import { ExperienceId, ExperiencesShopResponse } from "redmond";
import * as H from "history";
import * as actionTypes from "./constants";

export interface IFetchExperiencesShop {
  type: actionTypes.FETCH_EXPERIENCES_SHOP;
  history: H.History;
}

export const fetchExperiencesShop = (args: {
  history: H.History;
}): IFetchExperiencesShop => ({
  type: actionTypes.FETCH_EXPERIENCES_SHOP,
  ...args,
});

export interface ISetExperiencesShopDetails {
  type: actionTypes.SET_EXPERIENCES_SHOP_DETAILS;
  response: ExperiencesShopResponse;
}

export const setExperiencesShopDetails = (args: {
  response: ExperiencesShopResponse;
}): ISetExperiencesShopDetails => ({
  type: actionTypes.SET_EXPERIENCES_SHOP_DETAILS,
  ...args,
});

export interface ISetExperiencesShopDetailsCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED;
}

export const setExperiencesShopDetailsCallStateFailed =
  (): ISetExperiencesShopDetailsCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED,
  });

export interface ISetSelectedExperience {
  type: actionTypes.SET_SELECTED_EXPERIENCE;
  experienceId: ExperienceId;
}

export const setSelectedExperience = (
  experienceId: ExperienceId
): ISetSelectedExperience => ({
  type: actionTypes.SET_SELECTED_EXPERIENCE,
  experienceId,
});

export type ExperiencesShopActions =
  | IFetchExperiencesShop
  | ISetExperiencesShopDetails
  | ISetExperiencesShopDetailsCallStateFailed
  | ISetSelectedExperience;
