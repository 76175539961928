import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityFilter } from "./component";

import { IStoreState } from "../../../../reducers/types";

import {
  getCurrentFilterPrice,
  getExperiencesMaxPrice,
  getExperiencesPopularTagsList,
  getFilteredDurationTimes,
  getFilteredTags,
  getFilteredStartTimes,
  getFilteredTripAdvisorRating,
} from "../../reducer";

import {
  setFilterDurationTimes,
  setFilterTags,
  setFilterPrice,
  setFilterStartTimes,
  setFilterTripadvisorRating,
} from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  filteredTripAdvisorRating: getFilteredTripAdvisorRating(state),
  filteredStartTimes: getFilteredStartTimes(state),
  filteredDurationTimes: getFilteredDurationTimes(state),
  maxExperiencesPrice: getExperiencesMaxPrice(state),
  filteredPrice: getCurrentFilterPrice(state),
  experiencesPopularTagsList: getExperiencesPopularTagsList(state),
  filteredTags: getFilteredTags(state),
});

const mapDispatchToProps = {
  setFilterTripadvisorRating,
  setFilterStartTimes,
  setFilterDurationTimes,
  setFilterPrice,
  setFilterTags,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFilterProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityFilter = withRouter(
  connector(AvailabilityFilter)
);
