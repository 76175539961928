export enum ExperiencesShopNavTab {
  GALLERY = "Gallery",
  OVERVIEW = "Overview",
  WHAT_TO_EXPECT = "What to expect",
  CANCELLATION_POLICY = "Cancellation policy",
  WHATS_INCLUDED = "What's included",
  REVIEWS = "Reviews",
  TRAVELER_PHOTOS = "Traveler photos",
  CONTACT = "Contact",
  COMPARE = "Compare",
}

export const SHOP_NAV_TABS = [
  ExperiencesShopNavTab.GALLERY,
  ExperiencesShopNavTab.OVERVIEW,
  ExperiencesShopNavTab.WHAT_TO_EXPECT,
  ExperiencesShopNavTab.CANCELLATION_POLICY,
  ExperiencesShopNavTab.WHATS_INCLUDED,
  ExperiencesShopNavTab.REVIEWS,
  ExperiencesShopNavTab.TRAVELER_PHOTOS,
  ExperiencesShopNavTab.CONTACT,
  ExperiencesShopNavTab.COMPARE,
];
