import { Box } from "@material-ui/core";
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { isEqual } from "lodash-es";

import {
  ActionLink,
  CloseButtonIcon,
  MobileFloatingButton,
  MobilePopoverCard,
} from "halifax";
import {
  DurationRefinement,
  ExperiencesAvailabilitySort,
  ExperienceTag,
  PriceRefinement,
  StartTimeRefinement,
  TripadvisorRatingRefinement,
} from "redmond";

import "./styles.scss";
import { type MobileAvailabilityFilterProps } from "./container";
import {
  AvailabilitySort,
  DurationFilter,
  PopularFeaturesFilter,
  PriceFilter,
  StartTimeFilter,
  TripadvisorRatingFilter,
} from "../AvailabilityFilters";

export interface IMobileAvailabilityFilterProps
  extends RouteComponentProps,
    MobileAvailabilityFilterProps {
  isFilterModalOpen: boolean;
  onClose: () => void;
  displaySort: boolean;
}

export const MobileAvailabilityFilter = (
  props: IMobileAvailabilityFilterProps
) => {
  const {
    filteredTripAdvisorRating,
    setFilterTripadvisorRating,
    filteredStartTimes,
    setFilterStartTimes,
    filteredDurationTimes,
    setFilterDurationTimes,
    maxExperiencesPrice,
    filteredPrice,
    setFilterPrice,
    experiencesPopularTagsList,
    filteredTags,
    setFilterTags,
    sortOption,
    setSortOption,
    isFilterModalOpen,
    onClose,
    filtersChangedSinceLastSearch,
    displaySort,
  } = props;

  const [curTripAdvisorRating, setCurTripAdvisorRating] =
    useState<TripadvisorRatingRefinement>(filteredTripAdvisorRating);
  const [curStartTimeRefinement, setCurStartTimeRefinement] =
    useState<StartTimeRefinement[]>(filteredStartTimes);
  const [curDurationRefinement, setCurDurationRefinement] = useState<
    DurationRefinement[]
  >(filteredDurationTimes);
  const [curPriceRefinement, setCurPriceRefinement] =
    useState<PriceRefinement>(filteredPrice);
  const [curExpTags, setCurExpTags] = useState<ExperienceTag[]>(filteredTags);
  const [curSortOption, setCurSortOption] =
    useState<ExperiencesAvailabilitySort>(sortOption);

  const canReset = useMemo(
    () =>
      curTripAdvisorRating !== TripadvisorRatingRefinement.Any ||
      !isEqual(curStartTimeRefinement, []) ||
      !isEqual(curDurationRefinement, []) ||
      curPriceRefinement.maxPriceUSD !== maxExperiencesPrice?.fiat.value ||
      !isEqual(curExpTags, []) ||
      curSortOption !== ExperiencesAvailabilitySort.Recommended,
    [
      curTripAdvisorRating,
      curStartTimeRefinement,
      curDurationRefinement,
      curExpTags,
      curPriceRefinement,
      curSortOption,
    ]
  );

  const handleReset = useCallback(() => {
    setFilterTripadvisorRating(TripadvisorRatingRefinement.Any);
    setFilterStartTimes([]);
    setFilterDurationTimes([]);
    setFilterPrice({
      maxPriceUSD: maxExperiencesPrice?.fiat.value as number,
    });
    setFilterTags([]);
    setSortOption(ExperiencesAvailabilitySort.Recommended);
  }, [
    setFilterTripadvisorRating,
    setFilterStartTimes,
    setFilterDurationTimes,
    setFilterPrice,
    setFilterTags,
    setSortOption,
  ]);

  const handleApply = useCallback(() => {
    setFilterTripadvisorRating(curTripAdvisorRating);
    setFilterStartTimes(curStartTimeRefinement);
    setFilterDurationTimes(curDurationRefinement);
    setFilterPrice(curPriceRefinement);
    setFilterTags(curExpTags);
    setSortOption(curSortOption);
  }, [
    setFilterTripadvisorRating,
    setFilterStartTimes,
    setFilterDurationTimes,
    setFilterPrice,
    setFilterTags,
    setSortOption,
    curTripAdvisorRating,
    curStartTimeRefinement,
    curDurationRefinement,
    curPriceRefinement,
    curExpTags,
    curSortOption,
  ]);

  useEffect(() => {
    if (filtersChangedSinceLastSearch) {
      setCurTripAdvisorRating(filteredTripAdvisorRating);
      setCurStartTimeRefinement(filteredStartTimes);
      setCurDurationRefinement(filteredDurationTimes);
      setCurPriceRefinement(filteredPrice);
      setCurExpTags(filteredTags);
      setCurSortOption(sortOption);
    }
  }, [
    filtersChangedSinceLastSearch,
    filteredTripAdvisorRating,
    filteredStartTimes,
    filteredDurationTimes,
    maxExperiencesPrice,
    filteredPrice,
    experiencesPopularTagsList,
    filteredTags,
    sortOption,
  ]);

  return (
    <MobilePopoverCard
      open={isFilterModalOpen}
      onClose={onClose}
      fullScreen
      className="experiences-mobile-availability-filter-root"
      bottomButton={
        canReset ? (
          <MobileFloatingButton
            className="apply-filters-floating-button"
            wrapperClassName="apply-filters-floating-button-container"
            onClick={() => {
              handleApply();
              onClose();
            }}
          >
            Apply filters
          </MobileFloatingButton>
        ) : undefined
      }
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onClose}
        />
      }
      topLeftButton={
        canReset ? (
          <ActionLink
            className="filters-reset-button"
            content="Clear all filters"
            onClick={handleReset}
          />
        ) : undefined
      }
    >
      <Box className="mobile-availability-filters-container">
        <Box>
          {displaySort && (
            <AvailabilitySort
              availabilitySortOption={curSortOption}
              setAvailabilitySortOption={setCurSortOption}
            />
          )}
          <TripadvisorRatingFilter
            filteredTripAdvisorRating={curTripAdvisorRating}
            setFilterTripadvisorRating={setCurTripAdvisorRating}
          />
          <StartTimeFilter
            filteredStartTimes={curStartTimeRefinement}
            setFilterStartTimes={setCurStartTimeRefinement}
          />
          <DurationFilter
            filteredDurationTimes={curDurationRefinement}
            setFilterDurationTimes={setCurDurationRefinement}
          />
          {maxExperiencesPrice && (
            <PriceFilter
              maxPrice={maxExperiencesPrice}
              filterPrice={curPriceRefinement}
              setFilterPrice={setCurPriceRefinement}
            />
          )}
          <PopularFeaturesFilter
            popularFeaturesList={experiencesPopularTagsList}
            filteredPopularFeatures={curExpTags}
            setFilteredPopularFeatures={setCurExpTags}
          />
        </Box>
      </Box>
    </MobilePopoverCard>
  );
};
