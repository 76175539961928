import React, { useCallback, useEffect, useMemo, useState } from "react";

// types, constants
import { IResult, IIdExperiences, RecentHotelSearch } from "redmond";
import * as textConstants from "./textConstants";
import { ExperiencesSearchControlConnectorProps } from "./container";

// styles, components
import "./styles.scss";
import { Box } from "@material-ui/core";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import { ExperiencesSearchButton } from "./components/SearchButton";
import { BannerSeverity, Icon, IconName, NotificationBanner } from "halifax";

// helpers, utils
import clsx from "clsx";
import H from "history";
import { SEARCH_EXPERIENCES_BUTTON } from "../../textConstants";
import { CalendarPickerButton } from "./components/CalendarPickerButton";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../utils/queryStringHelpers";
import { isEqual } from "lodash-es";

export interface IExperiencesSearchControlProps
  extends ExperiencesSearchControlConnectorProps {
  className?: string;
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  showPreferencesBanner?: boolean;
  displaySearchOnChangeOnly?: boolean;
  updateParamOnSearch?: boolean;
}

const getSelectedOption = (option: IResult | null, value: IResult | null) => {
  const selection = value ? (value.id as IIdExperiences) : null;
  const opt = option ? (option.id as IIdExperiences) : null;
  return (
    !!selection &&
    !!opt &&
    selection.experiencesSelection.id === opt.experiencesSelection.id
  );
};

export const ExperiencesSearchControl = (
  props: IExperiencesSearchControlProps
) => {
  const {
    className,
    location,
    fromDate,
    untilDate,
    displaySearchOnChangeOnly = false,
    updateParamOnSearch,
    resetFilters,
    setFromDate,
    setUntilDate,
    setLocation,
    fetchInitialExperiencesAvailability,
  } = props;
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);
  const [curLocation, setCurLocation] = useState(location);
  const [curFromDate, setCurFromDate] = useState(fromDate);
  const [curUntilDate, setCurUntilDate] = useState(untilDate);

  useEffect(() => {
    setCurLocation(location);
  }, [location]);

  useEffect(() => {
    setCurFromDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setCurUntilDate(untilDate);
  }, [untilDate]);

  const isReadyToSearch = useMemo(
    () => !!curLocation && !!curFromDate && !!curUntilDate,
    [curLocation, curFromDate, curUntilDate]
  );

  useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [isReadyToSearch]);

  const handleSearchClick = useCallback(
    (history: H.History) => {
      if (isReadyToSearch) {
        history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            (curLocation?.id as IIdExperiences).experiencesSelection.searchTerm,
            curFromDate,
            curUntilDate
          )}`
        );

        if (updateParamOnSearch) {
          setFromDate(curFromDate);
          setUntilDate(curUntilDate);
          setLocation(curLocation);
          fetchInitialExperiencesAvailability(history);
          resetFilters();
        }
      } else {
        setHasMissingSearchInfoError(true);
      }
    },
    [isReadyToSearch, curFromDate, curUntilDate, curLocation]
  );

  const hasChanges = useMemo(
    () =>
      !isEqual(curLocation, location) ||
      !isEqual(curFromDate, fromDate) ||
      !isEqual(curUntilDate, untilDate),
    [curLocation, location, curFromDate, fromDate, curUntilDate, untilDate]
  );

  return (
    <Box className={clsx("experiences-search-control-root", className)}>
      <Box className="experiences-search-control-row">
        <Box className="experiences-search-inputs">
          <Box className="experiences-search-input-row">
            <LocationAutocomplete
              className="destination-auto-complete b2b"
              label={textConstants.LOCATION_AUTOCOMPLETE_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden />
              }
              hasMissingSearchInfoError={hasMissingSearchInfoError && !location}
              value={curLocation}
              setValue={setCurLocation}
            />
            <Box className={"date-input"}>
              <CalendarPickerButton
                classes={["date-pickers"]}
                fromDate={curFromDate}
                untilDate={curUntilDate}
                setFromDate={setCurFromDate}
                setUntilDate={setCurUntilDate}
                hasMissingSearchInfoError={
                  hasMissingSearchInfoError && !fromDate && !untilDate
                }
              />
            </Box>
          </Box>
        </Box>
        {(!displaySearchOnChangeOnly || hasChanges) && (
          <ExperiencesSearchButton
            className="experiences-search-control-button b2b"
            message={SEARCH_EXPERIENCES_BUTTON}
            onClick={handleSearchClick}
          />
        )}
      </Box>
      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className="missing-info-search-error-banner"
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
    </Box>
  );
};
