import React from "react";

export const VIEWING_EXPERIENCES_HEADER_TEXT = (locationName: string) =>
  `Viewing experiences in ${locationName}`;

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <>
      <span className="font-bold">
        Earn {earnRate}
        {multiplier} {currency}
      </span>{" "}
      on experiences with your ${productDisplayName} account.
    </>
  );
};

export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span><b>Earn ${earnRate}${multiplier}</b> ${currency} on experiences</span>`;
};

export const FETCH_AVAILABILITY_TEXT = "for experiences";

export const SEARCH_TITLE = (location: string | undefined) => {
  return location
    ? `Explore experiences in ${location.split(",")[0]}`
    : `Explore experiences`;
};
