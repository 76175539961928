import { IStoreState } from "../../../../reducers/types";

export const getExperiencesShopCallState = (state: IStoreState) =>
  state.experiencesShop.experiencesShopCallState;

export const getSelectedExperienceId = (state: IStoreState) =>
  state.experiencesShop.selectedExperienceId;

export const getExperiencesShopDetails = (state: IStoreState) =>
  state.experiencesShop.experiencesShopDetails;
