import React from "react";
import {
  Box,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";
import { GenericSlider, Icon, IconName, RadioDropdown } from "halifax";
import {
  DurationRefinement,
  ExperiencesAvailabilitySort,
  ExperienceTag,
  PriceRefinement,
  Prices,
  StartTimeRefinement,
  TripadvisorRatingRefinement,
} from "redmond";

// TODO: Make a helper method for render functions

const tripadvisorRatingFilterOptions = [
  TripadvisorRatingRefinement.Any,
  TripadvisorRatingRefinement.Great,
  TripadvisorRatingRefinement.VeryGood,
  TripadvisorRatingRefinement.Good,
];

const tripadvisorRatingFilterOptionsLabels: {
  [key in TripadvisorRatingRefinement]: string;
} = {
  Any: "Any",
  Great: "Great (4.5 and up)",
  VeryGood: "Very good (4 and up)",
  Good: "Good (3.5 and up)",
};

const renderTripadvisorRatingRadios = (
  options: TripadvisorRatingRefinement[]
) =>
  options.map((option) => (
    <FormControlLabel
      className="tripadvisor-rating-option"
      key={option}
      value={option}
      label={
        <span className="tripadvisor-rating-option-label">
          {tripadvisorRatingFilterOptionsLabels[option]}
        </span>
      }
      labelPlacement="end"
      control={<Radio className="tripadvisor-rating-option-radio" />}
    />
  ));

export const TripadvisorRatingFilter = ({
  filteredTripAdvisorRating,
  setFilterTripadvisorRating,
}: {
  filteredTripAdvisorRating: TripadvisorRatingRefinement;
  setFilterTripadvisorRating: (
    filteredTripAdvisorRating: TripadvisorRatingRefinement
  ) => void;
}): React.ReactElement | null => {
  return (
    <Box className={clsx("tripadvisor-rating-filter", "availability-filter")}>
      <Box className={clsx("filter-section-title-container")}>
        <Icon name={IconName.TripAdvisorIcon} />
        <Typography className={clsx("filter-section-title")}>
          Tripadvisor rating
        </Typography>
      </Box>
      <RadioGroup
        className="tripadvisor-rating-selection"
        value={filteredTripAdvisorRating}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFilterTripadvisorRating(
            event.target.value as TripadvisorRatingRefinement
          );
        }}
      >
        {renderTripadvisorRatingRadios(tripadvisorRatingFilterOptions)}
      </RadioGroup>
    </Box>
  );
};

const startTimeFilterOptions = [
  StartTimeRefinement.Morning,
  StartTimeRefinement.Afternoon,
  StartTimeRefinement.EveningAndNight,
];

const startTimeFilterOptionsLabels: {
  [key in StartTimeRefinement]: { title: string; subtitle: string };
} = {
  Morning: { title: "Morning", subtitle: "6:00 a.m. - 12:00 p.m." },
  Afternoon: { title: "Afternoon", subtitle: "12:00 p.m. - 5:00 p.m." },
  EveningAndNight: {
    title: "Evening and night",
    subtitle: "5:00 p.m. - 12:00 a.m.",
  },
};

const renderStartTimeOptions = (
  options: StartTimeRefinement[],
  filteredStartTimes: StartTimeRefinement[],
  setFilterStartTimes: (filterStartTimes: StartTimeRefinement[]) => void
) => {
  const isChecked = (option: StartTimeRefinement): boolean => {
    return filteredStartTimes.some(
      (filteredStartTime) => filteredStartTime === option
    );
  };

  const handleChange = (option: StartTimeRefinement) => {
    if (isChecked(option)) {
      const selectedAfterRemove = filteredStartTimes.filter(
        (selectedFilter: StartTimeRefinement) => selectedFilter !== option
      );
      setFilterStartTimes(selectedAfterRemove);
    } else {
      const newSelected = [
        ...filteredStartTimes,
        option,
      ] as StartTimeRefinement[];
      setFilterStartTimes(newSelected);
    }
  };

  return options.map((option) => (
    <FormControlLabel
      key={option}
      tabIndex={0}
      role="checkbox"
      className="checkbox-control-label"
      onKeyDown={(e) => {
        if (e && e.key === "Enter") {
          handleChange(option);
        }
      }}
      control={
        <Checkbox
          checked={isChecked(option)}
          tabIndex={-1}
          className="checkbox"
          onChange={() => handleChange(option)}
          value={option}
        />
      }
      label={
        <Box tabIndex={-1} className="start-time-label-wrapper">
          <Typography className={clsx("start-time-title")} variant="body1">
            {startTimeFilterOptionsLabels[option].title}
          </Typography>
          <Typography className={clsx("start-time-subtitle")} variant="body2">
            {startTimeFilterOptionsLabels[option].subtitle}
          </Typography>
        </Box>
      }
    />
  ));
};

export const StartTimeFilter = ({
  filteredStartTimes,
  setFilterStartTimes,
}: {
  filteredStartTimes: StartTimeRefinement[];
  setFilterStartTimes: (filterStartTimes: StartTimeRefinement[]) => void;
}): React.ReactElement | null => {
  return (
    <Box className={clsx("start-time-filter", "availability-filter")}>
      <Box className={clsx("filter-section-title-container")}>
        <Icon name={IconName.ClockIconThin} />
        <Typography className={clsx("filter-section-title")}>
          Start time
        </Typography>
      </Box>
      <FormControl
        className="start-time-filter-form-control"
        component="fieldset"
      >
        <FormGroup className="filter-form-control">
          {renderStartTimeOptions(
            startTimeFilterOptions,
            filteredStartTimes,
            setFilterStartTimes
          )}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

const durationFilterOptions = [
  DurationRefinement.LessThanOneHour,
  DurationRefinement.OneToFourHours,
  DurationRefinement.UpToOneDay,
  DurationRefinement.MoreThanOneDay,
];

const durationFilterOptionsLabels: {
  [key in DurationRefinement]: string;
} = {
  LessThanOneHour: "Less than 1 hour",
  OneToFourHours: "1 to 4 hours",
  UpToOneDay: "4 hours to 1 day",
  MoreThanOneDay: "More than 1 day",
};

const renderDurationOptions = (
  options: DurationRefinement[],
  filteredDurationTimes: DurationRefinement[],
  setFilterDurationTimes: (filteredDurationTimes: DurationRefinement[]) => void
) => {
  const isChecked = (option: DurationRefinement): boolean => {
    return filteredDurationTimes.some(
      (filteredDurationTime) => filteredDurationTime === option
    );
  };

  const handleChange = (option: DurationRefinement) => {
    if (isChecked(option)) {
      const selectedAfterRemove = filteredDurationTimes.filter(
        (selectedFilter: DurationRefinement) => selectedFilter !== option
      );
      setFilterDurationTimes(selectedAfterRemove);
    } else {
      const newSelected = [
        ...filteredDurationTimes,
        option,
      ] as DurationRefinement[];
      setFilterDurationTimes(newSelected);
    }
  };
  return options.map((option) => (
    <FormControlLabel
      key={option}
      tabIndex={0}
      role="checkbox"
      className="checkbox-control-label"
      onKeyDown={(e) => {
        if (e && e.key === "Enter") {
          handleChange(option);
        }
      }}
      control={
        <Checkbox
          checked={isChecked(option)}
          tabIndex={-1}
          className="checkbox"
          onChange={() => handleChange(option)}
          value={option}
        />
      }
      label={
        <Box tabIndex={-1} className="duration-label-wrapper">
          <Typography className={clsx("duration-title")} variant="body1">
            {durationFilterOptionsLabels[option]}
          </Typography>
        </Box>
      }
    />
  ));
};

export const DurationFilter = ({
  filteredDurationTimes,
  setFilterDurationTimes,
}: {
  filteredDurationTimes: DurationRefinement[];
  setFilterDurationTimes: (durationTimes: DurationRefinement[]) => void;
}): React.ReactElement | null => {
  return (
    <Box className={clsx("duration-filter", "availability-filter")}>
      <Box className={clsx("filter-section-title-container")}>
        <Icon name={IconName.StopwatchOutline} />
        <Typography className={clsx("filter-section-title")}>
          Duration
        </Typography>
      </Box>
      <FormControl
        className="duration-filter-form-control"
        component="fieldset"
      >
        <FormGroup className="filter-form-control">
          {renderDurationOptions(
            durationFilterOptions,
            filteredDurationTimes,
            setFilterDurationTimes
          )}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export const PriceFilter = ({
  maxPrice,
  filterPrice,
  setFilterPrice,
}: {
  maxPrice: Prices;
  filterPrice: PriceRefinement;
  setFilterPrice: (price: PriceRefinement) => void;
}): React.ReactElement | null => {
  const handleMaxPriceChangeDebounced = React.useMemo(
    () => debounce(setFilterPrice, 150),
    []
  );

  const handleMaxPriceChange = (_: number, newMaxPrice: number) => {
    handleMaxPriceChangeDebounced({ maxPriceUSD: newMaxPrice });
  };

  return (
    <Box className={clsx("price-filter", "availability-filter")}>
      <Box className={clsx("filter-section-title-container")}>
        <Icon name={IconName.MoneyOutlineThin} />
        <Typography className={clsx("filter-section-title")}>Price</Typography>
      </Box>
      <GenericSlider
        className={clsx("price-filter-slider")}
        onChange={handleMaxPriceChange}
        sliderType="singleThumb"
        step={1}
        chosenMax={filterPrice.maxPriceUSD}
        sliderMin={0}
        sliderMax={maxPrice.fiat.value}
        getLabel={(value: number) => `$${value}`}
        singleThumbType="max"
        alwaysShowTooltip
        showResetButton={false}
      />
    </Box>
  );
};

const renderPopularFeaturesFilterOptions = (
  options: ExperienceTag[],
  filteredPopularFeatures: ExperienceTag[],
  setFilteredPopularFeatures: (filteredPopularFeatures: ExperienceTag[]) => void
) => {
  const isChecked = (option: ExperienceTag): boolean => {
    return filteredPopularFeatures.some(
      (filteredPopularFeature) => filteredPopularFeature.id === option.id
    );
  };

  const handleChange = (option: ExperienceTag) => {
    if (isChecked(option)) {
      const selectedAfterRemove = filteredPopularFeatures.filter(
        (selectedFilter: ExperienceTag) => selectedFilter.id !== option.id
      );
      setFilteredPopularFeatures(selectedAfterRemove);
    } else {
      const newSelected = [
        ...filteredPopularFeatures,
        option,
      ] as ExperienceTag[];
      setFilteredPopularFeatures(newSelected);
    }
  };
  return options.map((option) => (
    <FormControlLabel
      key={option.id}
      tabIndex={0}
      role="checkbox"
      className="checkbox-control-label"
      onKeyDown={(e) => {
        if (e && e.key === "Enter") {
          handleChange(option);
        }
      }}
      control={
        <Checkbox
          checked={isChecked(option)}
          tabIndex={-1}
          className="checkbox"
          onChange={() => handleChange(option)}
          value={option}
        />
      }
      label={
        <Box tabIndex={-1} className="popular-feature-label-wrapper">
          <Typography className={clsx("popular-feature-title")} variant="body1">
            {option.name}
          </Typography>
        </Box>
      }
    />
  ));
};

export const PopularFeaturesFilter = ({
  popularFeaturesList,
  filteredPopularFeatures,
  setFilteredPopularFeatures,
}: {
  popularFeaturesList: ExperienceTag[];
  filteredPopularFeatures: ExperienceTag[];
  setFilteredPopularFeatures: (
    filteredPopularFeatures: ExperienceTag[]
  ) => void;
}): React.ReactElement | null => {
  return (
    <Box className={clsx("popular-features-filter", "availability-filter")}>
      <Box className={clsx("filter-section-title-container")}>
        <Icon name={IconName.BadgeRibbonWithoutCircle} />
        <Typography className={clsx("filter-section-title")}>
          Popular features
        </Typography>
      </Box>
      <FormControl
        className="popular-features-filter-form-control"
        component="fieldset"
      >
        <FormGroup className="filter-form-control">
          {renderPopularFeaturesFilterOptions(
            popularFeaturesList,
            filteredPopularFeatures,
            setFilteredPopularFeatures
          )}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

const availabilitySortOptions = [
  ExperiencesAvailabilitySort.Recommended,
  ExperiencesAvailabilitySort.Rating,
  ExperiencesAvailabilitySort.PriceAscending,
  ExperiencesAvailabilitySort.PriceDescending,
  ExperiencesAvailabilitySort.DurationAscending,
  ExperiencesAvailabilitySort.DurationDescending,
];

const availabilitySortOptionsLabels: {
  [key in ExperiencesAvailabilitySort]: string;
} = {
  Recommended: "Recommended",
  Rating: "Tripadvisor rating (high to low)",
  PriceAscending: "Price (low to high)",
  PriceDescending: "Price (hight to low)",
  DurationAscending: "Duration (short to long)",
  DurationDescending: "Duration (long to short)",
};

export const AvailabilitySortDropdown = ({
  availabilitySortOption,
  setAvailabilitySortOption,
}: {
  availabilitySortOption: ExperiencesAvailabilitySort;
  setAvailabilitySortOption: (
    availabilitySortOption: ExperiencesAvailabilitySort
  ) => void;
}): React.ReactElement | null => {
  return (
    <Box className={clsx("experiences-availability-sort-root")}>
      <Box className="experiences-availability-sort-container" tabIndex={0}>
        <RadioDropdown
          buttonClassName={clsx(
            "experiences-availability-sort-button",
            "b2b-shop-filter"
          )}
          dropdownLabel={
            <>
              <strong>Sort: </strong>
              {availabilitySortOptionsLabels[availabilitySortOption]}
            </>
          }
          options={availabilitySortOptions.map((option) => {
            return {
              value: option,
              label: availabilitySortOptionsLabels[option],
            };
          })}
          setOption={(option) =>
            setAvailabilitySortOption(option as ExperiencesAvailabilitySort)
          }
          selected={availabilitySortOption}
          groupAriaLabel={"Choose a Sort Option"}
          buttonAriaLabel={"Change Sort Option"}
          popoverClassName={clsx(
            "b2b",
            "experiences-availability-sort-popover"
          )}
          dropdownIcon={<Icon name={IconName.Sort} />}
          radioLabelPlacement="end"
        />
      </Box>
    </Box>
  );
};

const renderAvailabilitySortRadios = (options: ExperiencesAvailabilitySort[]) =>
  options.map((option) => (
    <FormControlLabel
      className="sort-rating-option"
      key={option}
      value={option}
      label={
        <span className="sort-rating-option-label">
          {availabilitySortOptionsLabels[option]}
        </span>
      }
      labelPlacement="end"
      control={<Radio className="sort-rating-option-radio" />}
    />
  ));

export const AvailabilitySort = ({
  availabilitySortOption,
  setAvailabilitySortOption,
}: {
  availabilitySortOption: ExperiencesAvailabilitySort;
  setAvailabilitySortOption: (
    availabilitySortOption: ExperiencesAvailabilitySort
  ) => void;
}): React.ReactElement | null => (
  <Box className="experiences-availability-sort availability-filter">
    <Box className="filter-section-title-container">
      <Icon name={IconName.Sort} />
      <Typography className="filter-section-title">Sort by</Typography>
    </Box>
    <RadioGroup
      className="sort-rating-selection"
      value={availabilitySortOption}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setAvailabilitySortOption(
          event.target.value as ExperiencesAvailabilitySort
        );
      }}
    >
      {renderAvailabilitySortRadios(availabilitySortOptions)}
    </RadioGroup>
  </Box>
);
