import { Box, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import "./styles.scss";
import { KeywordSearchConnectorProps } from "./container";
import { Icon, IconName } from "halifax";
import { IResult } from "redmond/build/common";
import { IIdExperiences } from "redmond/build/experiences-module";
import { KeywordAutocomplete } from "../KeywordAutocomplete";

export interface IKeywordSearchProps extends KeywordSearchConnectorProps {
  hideSearchBar?: boolean;
  displaySearchOnChange?: boolean;
  title: string;
}

export const KeywordSearch = (props: IKeywordSearchProps) => {
  const { hideSearchBar, title } = props;

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdExperiences) : null;
    const opt = option ? (option.id as IIdExperiences) : null;
    return (
      !!selection &&
      !!opt &&
      selection.experiencesSelection.id === opt.experiencesSelection.id
    );
  };

  return (
    <Box className="experiences-availability-keyword-search-container">
      <Box className={clsx("experiences-availability-keyword-search-control")}>
        <Box className="search-components-section">
          <Typography className="search-title">{title}</Typography>
          {!hideSearchBar && (
            <KeywordAutocomplete
              className={clsx("keyword-auto-complete", "b2b")}
              label={"Search by name or keyword"}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.MagnifyingGlass}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
