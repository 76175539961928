import { ExperiencesShopRequest, ExperiencesShopResponse } from "redmond";
import { call, put } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import dayjs from "dayjs";
import * as H from "history";
import queryStringParser from "query-string";
import { actions as searchActions } from "../../search/actions";
import { PATH_HOME } from "../../../utils/paths";
import { fetchExperienceShop } from "../../../api/v0/shop/fetchExperiencesShop";

export function* fetchExperiencesShopSaga(
  action: actions.IFetchExperiencesShop
) {
  try {
    // Always get request data from query params because the shop endpoint is always in a new tab with no previous state
    const requestData: ExperiencesShopRequest = yield call(
      getShopRequestParameters,
      action
    );

    const shopResponse: ExperiencesShopResponse = yield fetchExperienceShop(
      requestData
    );
    yield put(
      actions.setExperiencesShopDetails({
        response: shopResponse,
      })
    );
  } catch (e) {
    yield put(actions.setExperiencesShopDetailsCallStateFailed());
    Logger.debug(e);
  }
}

function* getShopRequestParameters(
  fetchExperiencesShop: actions.IFetchExperiencesShop
) {
  const history = fetchExperiencesShop.history;

  const parsedQueryString = parseQueryString(history);

  yield put(
    actions.setSelectedExperience({
      value: parsedQueryString.experienceId,
    })
  );
  yield put(
    searchActions.setFromDate(dayjs(parsedQueryString.fromDate).toDate())
  );
  yield put(
    searchActions.setUntilDate(dayjs(parsedQueryString.untilDate).toDate())
  );

  const shopRequestData: ExperiencesShopRequest = {
    id: {
      value: parsedQueryString.experienceId,
    },
    dateRange: {
      from: parsedQueryString.fromDate,
      until: parsedQueryString.untilDate,
    },
  };

  return shopRequestData;
}

export interface IExperiencesShopParsedQuery {
  fromDate: string;
  untilDate: string;
  experienceId: string;
}

const parseQueryString = (history: H.History): IExperiencesShopParsedQuery => {
  const queryString = history?.location?.search || "";

  const { experienceId, fromDate, untilDate } =
    queryStringParser.parse(queryString);

  if (!experienceId || !fromDate || !untilDate) {
    history.push(PATH_HOME);
  }

  return {
    fromDate: fromDate as string,
    untilDate: untilDate as string,
    experienceId: experienceId as string,
  };
};
