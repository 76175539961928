import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";

export interface IShoEarnInfoProps {
  isSkeleton: boolean;
}

export const ShopEarnInfo = (props: IShoEarnInfoProps) => {
  const {} = props;

  const earnRate = "5,467 miles";
  const earnText = `Earn ${earnRate} with your Venture X when you book this experience  through Capital One Travel.`;

  return (
    <Box className="experiences-shop-earn-info-container">
      <Icon
        name={IconName.PiggyBankCircleOutline}
        className="experiences-shop-piggy-bank"
      />
      <Typography className="experiences-shop-earn-info-body">
        {earnText}
      </Typography>
    </Box>
  );
};
