import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { MobileAvailabilitySearchControl } from "./component";

import { IStoreState } from "../../../../reducers/types";
import {
  getFromDate,
  getHasLocationAutocompleteError,
  getLocation,
  getLocationCategories,
  getLocationCategoriesLoading,
  getUntilDate,
} from "../../../search/reducer";
import {
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../../travel-wallet/reducer";
import {
  setFromDate,
  setUntilDate,
  setLocation,
  fetchLocationCategories,
  setLocationAutocompleteError,
} from "../../../search/actions/actions";
import { fetchInitialExperiencesAvailability } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  walletItemCount: getTravelWalletCredit(state)
    ? getTravelWalletOffers(state).length + 1
    : getTravelWalletOffers(state).length,
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  searchLocation: getLocation(state),
  locationCategories: getLocationCategories(state),
  loading: !!getLocationCategoriesLoading(state),
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
});

const mapDispatchToProps = {
  setFromDate,
  setUntilDate,
  setLocation,
  fetchInitialExperiencesAvailability,
  fetchLocationCategories,
  setLocationAutocompleteError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAvailabilitySearchControl = withRouter(
  connector(MobileAvailabilitySearchControl)
);
