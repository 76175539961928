export const FETCH_INITIAL_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/FETCH_INITIAL_EXPERIENCES_AVAILABILITY";
export type FETCH_INITIAL_EXPERIENCES_AVAILABILITY =
  typeof FETCH_INITIAL_EXPERIENCES_AVAILABILITY;

export const FETCH_FILTERED_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/FETCH_FILTERED_EXPERIENCES_AVAILABILITY";
export type FETCH_FILTERED_EXPERIENCES_AVAILABILITY =
  typeof FETCH_FILTERED_EXPERIENCES_AVAILABILITY;

export const FETCH_MORE_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/FETCH_MORE_EXPERIENCES_AVAILABILITY";
export type FETCH_MORE_EXPERIENCES_AVAILABILITY =
  typeof FETCH_MORE_EXPERIENCES_AVAILABILITY;

export const FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS =
  "experiencesAvailability/FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS";
export type FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS =
  typeof FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS;

export const STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY";
export type STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY =
  typeof STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY;

export const SET_EXPERIENCES_AVAILABILITY_RESULTS =
  "experiencesAvailability/SET_EXPERIENCES_AVAILABILITY_RESULTS";
export type SET_EXPERIENCES_AVAILABILITY_RESULTS =
  typeof SET_EXPERIENCES_AVAILABILITY_RESULTS;

export const SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED =
  "experiencesAvailability/SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED";
export type SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED;

export const FETCH_KEYWORD_CATEGORIES =
  "experiencesAvailability/FETCH_KEYWORD_CATEGORIES";
export type FETCH_KEYWORD_CATEGORIES = typeof FETCH_KEYWORD_CATEGORIES;

export const SET_KEYWORD_CATEGORIES =
  "experiencesAvailability/SET_KEYWORD_CATEGORIES";
export type SET_KEYWORD_CATEGORIES = typeof SET_KEYWORD_CATEGORIES;

export const SET_KEYWORD = "experiencesAvailability/SET_KEYWORD";
export type SET_KEYWORD = typeof SET_KEYWORD;

export const SET_KEYWORD_AUTOCOMPLETE_ERROR =
  "experiencesAvailability/SET_KEYWORD_AUTOCOMPLETE_ERROR";
export type SET_KEYWORD_AUTOCOMPLETE_ERROR =
  typeof SET_KEYWORD_AUTOCOMPLETE_ERROR;

export const SET_FILTER_TRIPADVISOR_RATING =
  "experiencesAvailability/SET_FILTER_TRIPADVISOR_RATING";
export type SET_FILTER_TRIPADVISOR_RATING =
  typeof SET_FILTER_TRIPADVISOR_RATING;

export const SET_FILTER_START_TIMES =
  "experiencesAvailability/SET_FILTER_START_TIMES";
export type SET_FILTER_START_TIMES = typeof SET_FILTER_START_TIMES;

export const SET_FILTER_DURATION =
  "experiencesAvailability/SET_FILTER_DURATION";
export type SET_FILTER_DURATION = typeof SET_FILTER_DURATION;

export const SET_FILTER_PRICE = "experiencesAvailability/SET_FILTER_PRICE";
export type SET_FILTER_PRICE = typeof SET_FILTER_PRICE;

export const SET_FILTER_TAGS = "experiencesAvailability/SET_FILTER_TAGS";
export type SET_FILTER_TAGS = typeof SET_FILTER_TAGS;

export const SET_SORT_OPTION = "experiencesAvailability/SET_SORT_OPTION";
export type SET_SORT_OPTION = typeof SET_SORT_OPTION;

export const FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE =
  "experiencesAvailability/FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE";
export type FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE =
  typeof FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE;

export const SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS =
  "experiencesAvailability/SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS";
export type SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS =
  typeof SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS;
