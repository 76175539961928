import { combineReducers } from "@reduxjs/toolkit";
import { reducer as experiencesRewards } from "../modules/rewards/reducer";
import { reducer as experiencesSearch } from "../modules/search/reducer";
import { reducer as experiencesAvailability } from "../modules/availability/reducer";
import { reducer as experiencesTravelWallet } from "../modules/travel-wallet/reducer";
import { reducer as experiencesShop } from "../modules/shop/reducer";

export const rootReducer = combineReducers({
  experiencesRewards,
  experiencesSearch,
  experiencesAvailability,
  experiencesTravelWallet,
  experiencesShop,
});
