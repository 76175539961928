import { call, race, take, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import {
  fetchExperiencesAvailabilitySaga,
  getAvailabilityRequestParametersSaga,
} from "./fetchExperiencesAvailabilitySaga";
import { IFetchExperiencesAvailability } from "../actions/actions";
import { fetchExperiencesAvailabilityAutocompleteSaga } from "./fetchExperiencesAvailabilityAutocompleteSaga";

export function* watchFetchExperiencesAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
    fetchExperiencesAvailabilitySaga
  );

  yield takeLatest(
    actionTypes.FETCH_FILTERED_EXPERIENCES_AVAILABILITY,
    fetchExperiencesAvailabilitySaga
  );

  yield takeLatest(
    actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY,
    function* (action: IFetchExperiencesAvailability) {
      yield race({
        task: call(fetchExperiencesAvailabilitySaga, action),
        cancel: take([
          actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY,
          actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
        ]),
      });
    }
  );
}

export function* watchFetchExperiencesAvailabilityRequestParams() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS,
    getAvailabilityRequestParametersSaga
  );

  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS,
    getAvailabilityRequestParametersSaga
  );

  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS,
    function* (action: IFetchExperiencesAvailability) {
      yield race({
        task: call(getAvailabilityRequestParametersSaga, action),
        cancel: take([]),
      });
    }
  );
}

export function* watchFetchExperiencesAvailabilityAutocomplete() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE,
    fetchExperiencesAvailabilityAutocompleteSaga
  );
}
