import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { ExperiencesShop } from "./component";
import { IStoreState } from "../../reducers/types";
import { getFromDate, getLocation, getUntilDate } from "../search/reducer";
import "./reducer";
import { fetchExperiencesShop, setSelectedExperience } from "./actions/actions";
import { getSelectedExperienceId } from "./reducer";
import { setFromDate, setUntilDate } from "../search/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getLocation(state),
  experienceName: "Default Loire Valley Castles Day Trip", //getExperienceName(state),
  selectedExperienceId: getSelectedExperienceId(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
});

const mapDispatchToProps = {
  setSelectedExperience,
  fetchExperiencesShop,
  setFromDate,
  setUntilDate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperiencesShop = withRouter(connector(ExperiencesShop));
