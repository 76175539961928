import { ConnectedProps, connect } from "react-redux";
import { withRouter } from "react-router";
import { ExperiencesAvailability } from "./component";
import { IStoreState } from "../../reducers/types";
import { getFromDate, getLocation, getUntilDate } from "../search/reducer";
import {
  getExperiencesAvailabilityCallState,
  getExperiencesByCategory,
  getExperiencesMap,
  getFiltersChangedSinceLastSearch,
  getSortOption,
} from "./reducer";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccountWithLargestValue,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../rewards/reducer";
import {
  fetchInitialExperiencesAvailability,
  getAvailabilityRequestParameters,
  fetchFilteredExperiencesAvailability,
  setSortOption,
} from "./actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getLocation(state),
  experiencesAvailabilityCallState: getExperiencesAvailabilityCallState(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
  experiencesMap: getExperiencesMap(state),
  experiencesByCategory: getExperiencesByCategory(state),
  rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  filtersChangedSinceLastSearch: getFiltersChangedSinceLastSearch(state),
  sortOption: getSortOption(state),
});

const mapDispatchToProps = {
  fetchInitialExperiencesAvailability,
  fetchFilteredExperiencesAvailability,
  getAvailabilityRequestParameters,
  setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesAvailability = withRouter(
  connector(ExperiencesAvailability)
);
