import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ShopComponentSkeleton } from "../../component";
import { ExperiencesShopNavTab } from "../../constants";

export interface IShopContactInformationProps {
  isSkeleton: boolean;
}

export const ShopContactInformation = (props: IShopContactInformationProps) => {
  const { isSkeleton } = props;

  const title = "Contact Information";

  const renderSkeleton = () => (
    <Box className="experiences-shop-skeleton">
      <Box className="section-wrapper-container">
        <Box className={clsx("section-wrapper")}>
          <ShopComponentSkeleton name="medium" />
          <ShopComponentSkeleton name="large" />
          <ShopComponentSkeleton name="medium" />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      className="experiences-shop-contact-information-container"
      id={ExperiencesShopNavTab.CONTACT}
    >
      <Typography className="experiences-shop-component-title">
        {title}
      </Typography>
      {isSkeleton ? renderSkeleton() : null}
    </Box>
  );
};
