import { connect } from "react-redux";
import { IResult } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getLocationCategories,
  getLocationCategoriesLoading,
} from "../../../../reducer";
import { fetchLocationCategories } from "../../../../actions/actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";

export const mapDispatchToProps = {
  fetchValueCategories: fetchLocationCategories,
};

export const mapStateToProps = (state: IStoreState) => {
  let loading = !!getLocationCategoriesLoading(state);

  return {
    overrideInputValue: undefined,
    valueCategories: getLocationCategories(state),
    loading,
  };
};

export const ConnectedLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
