import { Box, Typography } from "@material-ui/core";
import React, { useMemo, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { isEqual } from "lodash-es";

import { ActionLink } from "halifax";
import { TripadvisorRatingRefinement } from "redmond";

import "./styles.scss";
import { type AvailabilityFilterProps } from "./container";
import {
  DurationFilter,
  PopularFeaturesFilter,
  PriceFilter,
  StartTimeFilter,
  TripadvisorRatingFilter,
} from "../AvailabilityFilters";

export interface IAvailabilityFilterProps
  extends RouteComponentProps,
    AvailabilityFilterProps {}

export const AvailabilityFilter = (props: IAvailabilityFilterProps) => {
  const {
    filteredTripAdvisorRating,
    setFilterTripadvisorRating,
    filteredStartTimes,
    setFilterStartTimes,
    filteredDurationTimes,
    setFilterDurationTimes,
    maxExperiencesPrice,
    filteredPrice,
    setFilterPrice,
    experiencesPopularTagsList,
    filteredTags,
    setFilterTags,
  } = props;

  const canReset = useMemo(
    () =>
      filteredTripAdvisorRating !== TripadvisorRatingRefinement.Any ||
      !isEqual(filteredStartTimes, []) ||
      !isEqual(filteredDurationTimes, []) ||
      filteredPrice.maxPriceUSD !== maxExperiencesPrice?.fiat.value ||
      !isEqual(filteredTags, []),
    [
      filteredTripAdvisorRating,
      filteredStartTimes,
      filteredDurationTimes,
      filteredTags,
      filteredPrice,
      maxExperiencesPrice,
    ]
  );

  const handleReset = useCallback(() => {
    setFilterTripadvisorRating(TripadvisorRatingRefinement.Any);
    setFilterStartTimes([]);
    setFilterDurationTimes([]);
    setFilterPrice({ maxPriceUSD: maxExperiencesPrice?.fiat.value as number });
    setFilterTags([]);
  }, [
    setFilterTripadvisorRating,
    setFilterStartTimes,
    setFilterDurationTimes,
    setFilterPrice,
    setFilterTags,
  ]);

  return (
    <Box className="experiences-availability-all-filter-container">
      <Box className="experiences-availability-header">
        <Typography className="title" variant="h6">
          All filters
        </Typography>
        {canReset && (
          <ActionLink
            className="filters-reset-button"
            content="Clear all filters"
            onClick={handleReset}
          />
        )}
      </Box>
      <Box>
        <TripadvisorRatingFilter
          filteredTripAdvisorRating={filteredTripAdvisorRating}
          setFilterTripadvisorRating={setFilterTripadvisorRating}
        />
        <StartTimeFilter
          filteredStartTimes={filteredStartTimes}
          setFilterStartTimes={setFilterStartTimes}
        />
        <DurationFilter
          filteredDurationTimes={filteredDurationTimes}
          setFilterDurationTimes={setFilterDurationTimes}
        />
        {maxExperiencesPrice && (
          <PriceFilter
            maxPrice={maxExperiencesPrice}
            filterPrice={filteredPrice}
            setFilterPrice={setFilterPrice}
          />
        )}
        <PopularFeaturesFilter
          popularFeaturesList={experiencesPopularTagsList}
          filteredPopularFeatures={filteredTags}
          setFilteredPopularFeatures={setFilterTags}
        />
      </Box>
    </Box>
  );
};
