import { connect, ConnectedProps } from "react-redux";

import { KeywordSearch } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = () => {
  return {
    resetFilters: () => {},
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type KeywordSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedKeywordSearch = connector(KeywordSearch);
