import {
  ExperiencesAvailabilityRequest,
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilityResponse,
  IIdExperiences,
  ILocationQueryLabel,
  LocationQueryEnum,
  IResult,
  ICategorizedResponse,
  RatingRefinement,
  TripadvisorRatingRefinement,
} from "redmond";
import { call, put, putResolve, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import { fetchExperiencesAvailability } from "../../../api/v0/availability/fetchExperiencesAvailability";
import Logger from "../../../utils/logger";
import dayjs from "dayjs";
import { getFromDate, getLocation, getUntilDate } from "../../search/reducer";
import {
  getExperiencesAvailabilityNextPageToken,
  getFilteredDurationTimes,
  getFilteredStartTimes,
  getFilteredTags,
  getFilteredTripAdvisorRating,
  getFilterPrice,
  getSortOption,
  IExperiencesAvailabilityFilterState,
} from "../reducer";
import * as H from "history";
import queryStringParser from "query-string";
import { actions as searchActions } from "../../search/actions";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { PATH_HOME } from "../../../utils/paths";

export function* fetchExperiencesAvailabilitySaga(
  action: actions.IFetchExperiencesAvailability
) {
  try {
    let requestBody: ExperiencesAvailabilityRequest;
    const state: IStoreState = yield select();

    const requestData: {
      fromDate: Date | null;
      untilDate: Date | null;
      locationToSearch: IResult | null;
    } = yield call(getAvailabilityRequestParametersSaga, action);

    const { fromDate, untilDate, locationToSearch } = requestData;

    // TODO: Break these to helper functions
    const refinements: IExperiencesAvailabilityFilterState = {
      tripAdvisorRating: getFilteredTripAdvisorRating(state),
      duration: getFilteredDurationTimes(state),
      startTime: getFilteredStartTimes(state),
      price: getFilterPrice(state),
      tags: getFilteredTags(state),
      sort: getSortOption(state),
    };

    let ratingRefinement: RatingRefinement = { minTripAdvisorRating: 0 };
    switch (refinements.tripAdvisorRating) {
      case TripadvisorRatingRefinement.Good:
        ratingRefinement.minTripAdvisorRating = 3.5;
        break;
      case TripadvisorRatingRefinement.VeryGood:
        ratingRefinement.minTripAdvisorRating = 4;
        break;
      case TripadvisorRatingRefinement.Great:
        ratingRefinement.minTripAdvisorRating = 4.5;
        break;
      default:
        ratingRefinement.minTripAdvisorRating = 3;
        break;
    }

    switch (action.requestType) {
      case ExperiencesAvailabilityRequestEnum.InitialSearch:
      case ExperiencesAvailabilityRequestEnum.RequestParams: {
        if (JSON.stringify(requestData) === "{}") return;
        requestBody = {
          AvailabilityRequest: ExperiencesAvailabilityRequestEnum.InitialSearch,
          dateRange: {
            from: dayjs(fromDate).format("YYYY-MM-DD"),
            until: dayjs(untilDate).format("YYYY-MM-DD"),
          },
          experiencesSelection: (locationToSearch?.id as IIdExperiences)
            .experiencesSelection,
          // TODO - COTA-1537 : Once we have filtering/sorting this would need to accept those too
          refinement: {
            sort: refinements.sort,
            tags: refinements.tags ? refinements.tags : [],
            duration: refinements.duration ? refinements.duration : [],
            startTime: refinements.startTime ? refinements.startTime : [],
            price: refinements.price,
            rating: ratingRefinement,
          },
        };

        break;
      }
      case ExperiencesAvailabilityRequestEnum.FollowupSearch: {
        const nextPageToken = getExperiencesAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          nextPageToken: nextPageToken,
          AvailabilityRequest:
            ExperiencesAvailabilityRequestEnum.FollowupSearch,
        };

        break;
      }
    }
    let availabilityResponse: ExperiencesAvailabilityResponse =
      yield fetchExperiencesAvailability(requestBody);
    yield put(
      actions.setExperiencesAvailabilityResults({
        payload: availabilityResponse,
        responseType: action.requestType,
      })
    );
  } catch (e) {
    yield put(actions.setExperiencesAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}

export function* getAvailabilityRequestParametersSaga(
  fetchExperiencesAvailability: actions.IFetchExperiencesAvailability
) {
  const state: IStoreState = yield select();
  const history = fetchExperiencesAvailability.history;

  let { fromDate, untilDate, location } = getExistingStateVariables(state);

  const parsedQueryString = parseQueryString(history);

  let locationToSearch: IResult | null = location;
  if (!locationToSearch) {
    const { correspondingLocation } = yield fetchLocation(parsedQueryString);
    locationToSearch = correspondingLocation;
    yield put(searchActions.setLocation(locationToSearch));
  }

  if (!fromDate || fromDate !== parsedQueryString.fromDate) {
    fromDate = parsedQueryString.fromDate;
    yield putResolve(searchActions.setFromDate(fromDate));
  }

  if (!untilDate || untilDate !== parsedQueryString.untilDate) {
    untilDate = parsedQueryString.untilDate;
    yield putResolve(searchActions.setUntilDate(untilDate));
  }

  if (
    shouldRedirect({
      fromDate,
      untilDate,
      locationToSearch,
    })
  ) {
    fetchExperiencesAvailability.history.push(PATH_HOME);
    return {};
  }

  return {
    fromDate,
    untilDate,
    locationToSearch,
  };
}

const getExistingStateVariables = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    location: getLocation(state),
  };
};

const shouldRedirect = ({ fromDate, untilDate, locationToSearch }: any) =>
  !fromDate || !untilDate || !locationToSearch;

export interface IExperiencesAvailabilityParsedQuery {
  fromDate: Date | null;
  untilDate: Date | null;
  locationName: string | null;
}

const parseQueryString = (
  history: H.History
): IExperiencesAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const { fromDate, untilDate, locationName } =
    queryStringParser.parse(queryString);

  return {
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    locationName: locationName as string,
  };
};

export function* fetchLocation(
  parsedQueryString: IExperiencesAvailabilityParsedQuery
) {
  if (!parsedQueryString.locationName)
    return { correspondingLocation: undefined };

  const location = decodeURIComponent(parsedQueryString.locationName);

  const requestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: location,
  };

  const { categories } = yield fetchLocationAutocomplete(requestBody);
  yield put(searchActions.setLocationCategories(categories));

  const correspondingLocations = categories.flatMap(
    (category: ICategorizedResponse) =>
      category.results.find((result) =>
        result.label.toLowerCase().includes(location.toLowerCase())
      )
  );

  return {
    correspondingLocation:
      correspondingLocations.length > 0 ? correspondingLocations[0] : null,
  };
}
