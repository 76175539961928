import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  MobilePopoverCard,
  DesktopPopupModal,
  IconName,
  Icon,
  ActionLink,
  CloseButtonIcon,
} from "halifax";

import viatorFullLogo from "../../../assets/b2b/viator-full-logo.png";
import styles from "./styles.module.scss";

export interface IBadgeExcellenceModalProps {
  onClose: () => void;
  isOpen: boolean;
  isMobile?: boolean;
}

const MODAL_SECTIONS = [
  {
    title: "Traveler love it",
    list: [
      {
        icon: <Icon name={IconName.ThumbUpIcon} />,
        label: "Average rating",
        value: "4.5+",
      },
      {
        icon: <Icon name={IconName.ReviewWithStarIcon} />,
        label: "Number of reviews",
        value: "15+",
      },
    ],
  },
  {
    title: "All the information you need",
    list: [
      {
        icon: <Icon name={IconName.InfoCircle} />,
        label: "Complete experience details",
        value: <Icon name={IconName.CheckCircleFilledGreen} />,
      },
      {
        icon: <Icon name={IconName.TripAdvisorIcon} />,
        label: "Connected to a Tripadvisor listing",
        value: <Icon name={IconName.CheckCircleFilledGreen} />,
      },
      {
        icon: <Icon name={IconName.PictureIcon} />,
        label: "Number of quality photos",
        value: "6+",
      },
    ],
  },
  {
    title: "First class flexibility",
    list: [
      {
        icon: <Icon name={IconName.CalendarCancellationIcon} />,
        label: "Standard cancellation policy",
        value: <Icon name={IconName.CheckCircleFilledGreen} />,
      },
      {
        icon: <Icon name={IconName.MobilePhoneIcon} />,
        label: "Accepts mobile ticket",
        value: <Icon name={IconName.CheckCircleFilledGreen} />,
      },
      {
        icon: <Icon name={IconName.MobileConfirmationIcon} />,
        label: "Instantly confirmed after booking",
        value: <Icon name={IconName.CheckCircleFilledGreen} />,
      },
    ],
  },
];

const ModalContent = () => (
  <>
    <Box className={styles["top-content-container"]}>
      <Box className={styles["title-container"]}>
        <img src={viatorFullLogo} alt="viator logo" aria-label="viator logo" />
        <Divider className={styles["title-divider"]} orientation="vertical" />
        <Typography className={styles.title}>Badge of excellence</Typography>
      </Box>
      <Typography className={styles.description} variant="body1">
        Capital One Travel curates experiences from the best Viator has to
        offer. This experience is highly rated by travelers and meets Viator’s
        top quality standards.
      </Typography>
    </Box>
    <Box className={styles["bottom-content-container"]}>
      {MODAL_SECTIONS.map(({ title, list }) => (
        <Box>
          <Typography className={styles["list-title"]}>{title}</Typography>
          <Box className={styles["section-grid"]}>
            {list.map(({ label, icon, value }) => (
              <Box key={label} className={styles["section-grid-row"]}>
                <Box className={styles["row-icon-name-container"]}>
                  {icon}
                  <Typography className={styles["row-name"]}>
                    {label}
                  </Typography>
                </Box>
                <Box className={styles["row-description-container"]}>
                  {value}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  </>
);

export const BadgeExcellenceModal = ({
  onClose,
  isOpen,
  isMobile = false,
}: IBadgeExcellenceModalProps) => {
  if (isMobile) {
    return (
      <MobilePopoverCard
        role="tooltip"
        id="badge-excellence-modal"
        open={isOpen}
        className={styles["mobile-badge-excellence-modal-popup"]}
        onClose={onClose}
        topRightButton={
          <ActionLink
            className={styles["modal-close-button"]}
            onClick={onClose}
            content={<CloseButtonIcon />}
            label="Close"
            showTappableArea
          />
        }
      >
        <ModalContent />
      </MobilePopoverCard>
    );
  }

  return (
    <DesktopPopupModal
      open={isOpen}
      className={styles["badge-excellence-popup"]}
      onClose={onClose}
      invisibleBackdrop={false}
    >
      <ModalContent />
    </DesktopPopupModal>
  );
};
