import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { ShopPhotoGallery } from "./component";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ShopPhotoGalleryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedShopPhotoGallery = connector(ShopPhotoGallery);
