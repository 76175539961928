export const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId);

  if (section) {
    const BANNER_HEIGHT =
      document.querySelector(".b2b-portal-banner-root")?.getBoundingClientRect()
        .height ?? 0;
    const HEADER_HEIGHT =
      document.querySelector(".app-header")?.getBoundingClientRect().height ??
      0;
    const NAV_HEIGHT =
      document
        .querySelector(".experiences-shop-navigation")
        ?.getBoundingClientRect().height ?? 0;
    const MARGIN = 20;
    const yOffset = BANNER_HEIGHT + HEADER_HEIGHT + NAV_HEIGHT + MARGIN;

    window.scrollTo({
      top: section.offsetTop - yOffset,
      behavior: "smooth",
    });
  }
};
