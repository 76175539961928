import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchExperiencesShopSaga } from "./fetchExperiencesShopSaga";

export function* watchFetchExperiencesShop() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_SHOP,
    fetchExperiencesShopSaga
  );
}
