import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import {
  getLocation,
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";
import { IResult } from "redmond/build/common";
import {
  ExperienceAvailability,
  ExperienceTag,
  GroupedExperienceIds,
  PriceRefinement,
  TripadvisorRatingRefinement,
} from "redmond";

export const getExperiencesAvailabilityCallState = (state: IStoreState) =>
  state.experiencesAvailability.experiencesAvailabilityCallState;

export const getExperiencesAvailabilityResponse = (state: IStoreState) =>
  state.experiencesAvailability.availabilityResponse;

export const getExperiencesAvailabilityNextPageToken = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse) => {
    return availabilityResponse?.nextPageToken;
  }
);

export const getExperiences = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): ExperienceAvailability[] => {
    if (!availabilityResponse) {
      return [];
    }
    return availabilityResponse.experiences;
  }
);

export const getExperiencesMap = createSelector(
  getExperiences,
  (experiences) => {
    let experiencesMap = new Map();
    experiences.map((experience) => {
      experiencesMap.set(experience.id.value, experience);
    });

    return experiencesMap;
  }
);

export const getExperiencesMaxPrice = createSelector(
  getExperiences,
  (experiences) => {
    if (experiences.length <= 0) return null;
    let experienceMaxPrice = experiences[0].bestPrice;
    experiences.map((experience) => {
      experienceMaxPrice =
        experience.bestPrice.fiat.value > experienceMaxPrice.fiat.value
          ? experience.bestPrice
          : experienceMaxPrice;
    });

    return experienceMaxPrice;
  }
);

export const getExperiencesPopularTagsList = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): ExperienceTag[] => {
    if (!availabilityResponse) {
      return [];
    }
    return availabilityResponse.popularFeatureTags.map((tag) => {
      return tag.tag;
    });
  }
);

export const getExperiencesByCategory = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): GroupedExperienceIds[] => {
    if (!availabilityResponse) {
      return [];
    }

    return availabilityResponse.experiencesByCategory;
  }
);

export const getExistingExperiencesAvailabilityRequestParameters =
  createSelector(
    getLocation,
    getFromDate,
    getUntilDate,

    (
      location,
      departureDate,
      returnDate
    ): {
      location: IResult | null;
      departureDate: Date | null;
      returnDate: Date | null;
    } => ({
      location,
      departureDate,
      returnDate,
    })
  );

export const getExperiencesQueryParams = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate) => ({
    fromDate,
    untilDate,
  })
);

export const getKeyword = (state: IStoreState): IResult | null =>
  state.experiencesAvailability.keyword;

export const getKeywordCategories = (state: IStoreState) =>
  state.experiencesAvailability.keywordCategories;

export const getKeywordCategoriesLoading = (state: IStoreState) =>
  state.experiencesAvailability.keywordCategoriesLoading;

export const getHasKeywordAutocompleteError = (state: IStoreState) =>
  state.experiencesAvailability.hasKeywordAutocompleteError;

export const getFilteredTripAdvisorRating = (state: IStoreState) =>
  state.experiencesAvailability.tripAdvisorRating
    ? state.experiencesAvailability.tripAdvisorRating
    : TripadvisorRatingRefinement.Any;

export const getFilteredStartTimes = (state: IStoreState) =>
  state.experiencesAvailability.startTime
    ? state.experiencesAvailability.startTime
    : [];

export const getFilteredDurationTimes = (state: IStoreState) =>
  state.experiencesAvailability.duration
    ? state.experiencesAvailability.duration
    : [];

export const getFilterPrice = (state: IStoreState) =>
  state.experiencesAvailability.price;

export const getCurrentFilterPrice = createSelector(
  getExperiencesMaxPrice,
  getFilterPrice,
  (experiencesMaxPrice, filterPrice): PriceRefinement => {
    return filterPrice
      ? filterPrice
      : experiencesMaxPrice
      ? { maxPriceUSD: experiencesMaxPrice.fiat.value }
      : { maxPriceUSD: 0 };
  }
);

export const getFilteredTags = (state: IStoreState) =>
  state.experiencesAvailability.tags ? state.experiencesAvailability.tags : [];

export const getFiltersChangedSinceLastSearch = (state: IStoreState) =>
  state.experiencesAvailability.filtersChangedSinceLastSearch;

export const getSortOption = (state: IStoreState) =>
  state.experiencesAvailability.sort;
